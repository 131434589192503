export const base64ToBytes = (base64String) => {
    try {
      const binaryString = atob(base64String); // Декодируем base64 в бинарную строку
      const len = binaryString.length;
      const bytes = new Uint8Array(len); // Создаем массив байтов
  
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i); // Преобразуем символы в байты
      }
  
      return bytes;
    } catch (error) {
      console.error('Error converting base64 to bytes:', error);
      return null;
    }
  };
  