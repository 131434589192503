import api from './index';

export const getExitData = async (
  token,
  pageIndex,
  pageSize,
  filters = [],
  endDate, 
  startDate
) => {
  try {
    let queryParams;
  if (startDate) {
    queryParams = new URLSearchParams({
      endDate,
      startDate,
  }).toString();
  } else {
    queryParams = new URLSearchParams({
      endDate,
  }).toString();
  }
    const response = await api.post(
      `/api/exit/search?${queryParams}`,
      {
        filter: filters,
        pageSize: pageSize,
        pageIndex: pageIndex,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data.successful) {
      return response.data.data;
    } else {
      return { data: null, error: response.data.error.message }; 
    }
  } catch (error) {
    return { data: null, error: error.message || 'Request failed' }; 
  }
};

export const getStatisticsExit = async (token) => {
    try {
      const response = await api.get(`/api/exit/statistics`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.successful) {
        return response.data;
      } else {
        console.error('Error:', response.data.error.message);
        return response.data.error.message;
      }
    } catch (error) {
      console.error(
        'Failed to fetch statistics:',
        error.response ? error.response.data : error.message || error
      );
      throw error;
    }
  };

  export const uploadExit = async (token) => {
    try {
      const response = await api.post(
        '/api/exit/export',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.data.successful) {
        return response.data;
      } else {
        throw new Error(response.data.error?.message || 'Unknown error occurred');
      }
    } catch (error) {
      return {
        successful: false,
        error: {
          message:
            error.response?.data?.error?.message ||
            error.message ||
            'Something went wrong',
        },
      };
    }
  };