import React from 'react';

const RegistrationIdModal = ({ isOpen, onClose }) => {
	if (!isOpen) return null;

	return (
		<div className="absolute mt-14 bg-white px-4 pb-4 rounded-lg z-50 font-poppins w-1/3">
			<div className="flex items-center justify-between mb-2">
				<h2 className="text-sm font-semibold text-mc_red text-center w-full mt-4">
					What is a Registration ID?
				</h2>
				<button onClick={onClose}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						strokeWidth="1.5"
						stroke="#444444"
						className="size-4"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M6 18 18 6M6 6l12 12"
						/>
					</svg>
				</button>
			</div>
			<div className="font-medium text-[11px] text-center text-black">
				Since our service is by invitation only, please request from your agent
				to provide you a{' '}
				<span className="text-mc_red font-semibold text-sm">
					{' '}
					“Registration ID”{' '}
				</span>
				so you can register with us.
			</div>
		</div>
	);
};

export default RegistrationIdModal;
