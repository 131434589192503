import React from 'react';

const AgentTableHead = ({ sortByName, sortByTotalCustomers }) => {
  return (
    <div className="flex items-center md:px-6 px-1 py-2 font-bold text-sm bg-white">
      <span className="tr_style w-1/7">№</span>
      <span className="tr_style w-1/7">
          Agent
          <button onClick={sortByName}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="size-6 p-0.5 text-purple-800"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 7.5 7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
              />
            </svg>
          </button>
      </span>
      <span className="tr_style w-1/7">Country</span>
      <span className="tr_style w-1/7">Status</span>
      <span className="tr_style w-1/7">
        Total Clientele
        <button onClick={sortByTotalCustomers}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="size-6 p-0.5 text-purple-800"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 7.5 7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
            />
          </svg>
        </button>
      </span>
      <span className="tr_style w-1/7">Since</span>
      <span className="tr_style w-1/7">Action</span>
    </div>
  );
};

export default AgentTableHead;
