import React, { useEffect, useRef } from 'react';

const SearchDropdown = ({
	countries,
	onCountrySelect,
	onClose,
	searchValue,
	setSearchValue,
	style,
}) => {
	const dropdownRef = useRef(null);

	const selectCountryHandler = (e, country) => {
		e.preventDefault();
		onCountrySelect(country.imageUrl, country.countryCode, country.id);
		setSearchValue('');
		onClose();
	};

	// const handleMouseLeave = (event) => {
	// 	if (
	// 		dropdownRef.current &&
	// 		event.relatedTarget instanceof Node &&
	// 		!dropdownRef.current.contains(event.relatedTarget)
	// 	) {
	// 		onClose();
	// 		setSearchValue('');
	// 	}
	// };

	useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                onClose();
				setSearchValue('');
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        document.addEventListener('touchstart', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            document.removeEventListener('touchstart', handleOutsideClick);
        };
    }, [onClose]);

	return (
		<div
			ref={dropdownRef}
			// onMouseLeave={handleMouseLeave}
			className="absolute bg-white py-3 z-50 font-poppins overflow-y-scroll max-h-60"
			style={style}
		>
			<div className="w-full">
				<input
					placeholder="Search.."
					value={searchValue}
					onChange={(e) => setSearchValue(e.target.value)}
					className="block w-full h-auto p-1 text-sm font-normal rounded-[5px] border-none border-mc_light_grey outline-none placeholder:text-sm"
				/>
				<hr className="mb-1 sm:mb-3" />
				{countries.length > 0 ? (
					countries.map((country) => (
						<button
							key={country.id}
							onClick={(e) => selectCountryHandler(e, country)}
							className="p-1 sm:p-2 text-sm sm:text-base flex justify-between w-full hover:bg-mc_blue hover:bg-opacity-25"
						>
							<div className="flex items-baseline">
								<img src={country.imageUrl} alt="" className="w-5 sm:w-6" />
								<div className="ml-2">{country.name}</div>
							</div>
							<div>{country.countryCode}</div>
						</button>
					))
				) : (
					<div className="p-1 sm:p-2 text-sm sm:text-base">No results found</div>
				)}
			</div>
		</div>
	);
};

export default SearchDropdown;
