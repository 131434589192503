import React, { useRef, useEffect } from 'react';

const ResetPasswordModal = ({ isOpen, onClose, width }) => {
	
	const modalRef = useRef();

	useEffect(() => {
        const handleOutsideClick = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        document.addEventListener('touchstart', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            document.removeEventListener('touchstart', handleOutsideClick);
        };
    }, [onClose]);

	if (!isOpen) return null;

	return (
		<div
			style={{ width: `${width}px` }}
			className="absolute bg-white rounded-lg z-50 font-poppins px-3 pb-3"
			ref={modalRef}
		>
			<div className="relative">
				<h2 className="font-semibold text-mc_purple text-sm sm:text-base text-center w-full my-2 sm:my-3">
					Reset Password
				</h2>
				<button onClick={onClose} className="absolute top-0 right-0">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						strokeWidth="1.5"
						stroke="#444444"
						className="size-4"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M6 18 18 6M6 6l12 12"
						/>
					</svg>
				</button>
			</div>
			<div className="text-center text-[10px] sm:text-xs font-base">
				If you have forgotten your password, please contact your{' '}
				<span className="text-mc_red font-semibold">“Agent”</span> to reset it. We apologize
				for any inconvenience; this is for security purposes.
			</div>
		</div>
	);
};

export default ResetPasswordModal;
