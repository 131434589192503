import React, { useState } from 'react';
import RegistrationIdModal from './modals/RegistrationIdModal';
import RefferalCodeModal from './modals/RefferalCodeModal';
import { Link } from 'react-router-dom';

const UserRegistrationId = ({ onSubmit, errorMessage }) => {
    const [isRegistrationIdModal, setIsRegistrationIdModal] = useState(false);
    const [isRefferalCodeModal, setIsRefferalCodeModal] = useState(false);
    const [registrationId, setRegistrationId] = useState('');

    const RegistrationIdModalHandler = () => {
        setIsRegistrationIdModal(!isRegistrationIdModal);
        setIsRefferalCodeModal(false);
    };

    const RefferalCodeModalHandler = () => {
        setIsRefferalCodeModal(!isRefferalCodeModal);
        setIsRegistrationIdModal(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(registrationId);
        setRegistrationId('');
    };

    return (
        <div className='font-poppins flex flex-col items-center px-4 py-3 md:px-8 md:py-12 mt-12'>
            <div className='text-mc_purple font-medium text-sm md:text-lg lg:text-2xl text-center mb-6 sm:mb-8'>
                Currently, our services is by <br className='sm:hidden' /> invitation-only.  <br />
                Please get a “
                <span className='text-mc_red'>Registration ID</span>” <br className='sm:hidden' /> from your
                Agent.
            </div>
            <div className='max-w-md w-full'>
                <form className='space-y-5 sm:space-y-6 mb-6 sm:mb-8' onSubmit={handleSubmit}>
                    <div>
                        <label
                            htmlFor='registrationId'
                            className='block font-normal text-xs sm:text-sm text-mc_grey mb-2'
                        >
                            Registration ID
                        </label>
                        <div className='relative'>
                            <input
                                id='registrationId'
                                name='registrationId'
                                type='text'
                                className={`block w-full h-10 sm:h-11 p-3 font-normal rounded-xl border border-mc_grey outline-none ${
                                    errorMessage
                                        ? 'bg-red-500 bg-opacity-30'
                                        : 'bg-white'
                                } outline-none text-sm sm:text-base`}
                                onChange={(e) =>
                                    setRegistrationId(e.target.value)
                                }
                            />
                            {errorMessage && (
                                <span className='absolute text-xs sm:text-sm inset-x-0 top-full text-center text-red-500'>
                                    {errorMessage}
                                </span>
                            )}
                        </div>
                    </div>
                    <div>
                        <button
                            className='flex items-center w-full h-10 sm:h-11 p-3 font-medium rounded-xl text-black shadow-md outline-none bg-opacity-50 bg-mc_blue text-sm sm:text-base'
                            type='submit'
                        >
                            <span className='grow'>Next</span>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 24 24'
                                fill='#5C5C5C'
                                className='size-4'
                            >
                                <path
                                    fillRule='evenodd'
                                    d='M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z'
                                    clipRule='evenodd'
                                />
                            </svg>
                        </button>
                    </div>
                </form>
                <div className='flex flex-col items-end'>
                    <button
                        onClick={RegistrationIdModalHandler}
                        className='hidden w-full sm:flex items-center h-11 sm:h-12 rounded-xl bg-opacity-25 bg-mc_blue justify-between px-3'
                    >
                        <span className='font-normal text-xs sm:text-sm text-mc_grey'>
                            What is Registration ID
                        </span>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 24 24'
                            fill='#D81E06'
                            className='size-5 sm:size-6'
                        >
                            <path
                                fillRule='evenodd'
                                d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 0 1-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 0 1-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 0 1-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584ZM12 18a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z'
                                clipRule='evenodd'
                            />
                        </svg>
                    </button>
                    <RegistrationIdModal
                        isOpen={isRegistrationIdModal}
                        onClose={RegistrationIdModalHandler}
                    />
                </div>
                <div className='text-xs sm:text-sm w-full sm:mt-4 font-normal text-center'>
                    Already have an account?
                    <span className='ml-1 text-mc_blue underline'>
                        <Link to='/auth/login'>Log in here.</Link>
                    </span>
                </div>

                <hr className='w-full h-1 border-mc_grey opacity-50 my-4' />
                <div className='relative px-3'>
                    <div className='text-black font-normal text-xs sm:text-sm md:text-base'>
                        <span className='text-mc_red font-semibold'>
                            Important:{' '}
                        </span>
                        <br />
                        Please ensure you have registered an exchange account
                        using our Referral Code.
                    </div>
                    <button
                        className='absolute bottom-0 right-3'
                        onClick={RefferalCodeModalHandler}
                    >
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 24 24'
                            fill='#D81E06'
                            className='size-5 sm:size-6 hidden sm:block'
                        >
                            <path
                                fillRule='evenodd'
                                d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 0 1-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 0 1-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 0 1-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584ZM12 18a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z'
                                clipRule='evenodd'
                            />
                        </svg>
                    </button>
                    <RefferalCodeModal
                        isOpen={isRefferalCodeModal}
                        onClose={RefferalCodeModalHandler}
                    />
                </div>
            </div>
        </div>
    );
};

export default UserRegistrationId;
