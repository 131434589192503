import React from 'react';

export function RadioValue({ name, items = [], value = null, onChange, disabled, page='settings' }) {
	const safeItems = Array.isArray(items) ? items : [];

	return (
		<div className="flex justify-between">
			{safeItems.map((item) => (
				<div key={item.value} className="flex items-center">
					<input
						name={name}
						type="radio"
						value={item.value}
						id={name + item.value}
						checked={value === item.value}
						onChange={(e) => onChange(Number(e.target.value))} 
						disabled={disabled}
						className="hidden"
					/>
					<label
						htmlFor={name + item.value}
						className={`flex items-center${disabled ? '' : ' cursor-pointer '} `}
					>
						<span className={`md:mr-1 mr-1.5 ${page ===`settings`? 'md:text-[10px] xl:text-xs text-[8px] font-bold': 'sm:text-sm text-xs '}  text-black`}>
							{item.label}
						</span>
						<div
							className={`xl:w-4 xl:h-4 md:w-3 md:h-3 w-2 h-2 rounded-full border-2 ${
								value === item.value
									? 'bg-btn-green border-btn-green'
									: 'bg-grey-light border-grey-main'
							}`}
						>
							<div className="w-full h-full bg-grey-light rounded-full flex items-center justify-center">
								<div
									className={`xl:w-2 xl:h-2 md:w-1 md:h-1 w-2/3 h-2/3 rounded-full ${
										value === item.value ? 'bg-btn-green' : 'bg-grey-main'
									}`}
								></div>
							</div>
						</div>
					</label>
				</div>
			))}
		</div>
	);
}

export default RadioValue;
