import React from 'react';
import BarChart from './BarChart';

const CustomerStatistic = ({ customerData }) => {
  const difference =
    (customerData?.currentWalletAmount || 0) -
    (customerData?.startWalletAmount || 0);
  const percentageDifference =
    customerData?.startWalletAmount !== 0
      ? ((customerData?.currentWalletAmount - customerData?.startWalletAmount) /
          customerData?.startWalletAmount) *
        100
      : 0;
  return (
    <div className="p-2 lg:p-8">
      {/* Top Cards */}
      <div className="grid grid-cols-2 md:grid-cols-2 xl:gap-x-14 gap-y-10 md:gap-y-0 gap-x-10 mb-8">
      <div className="bg-[#450892] text-white rounded-lg shadow-md flex justify-between pl-5 pr-16 py-10">
      <div className="w-32 h-32 md:w-32 md:h-32 lg:w-28 lg:h-28 xl:w-32 xl:h-32 2xl:w-40 2xl:h-40 flex-shrink-0 bg-none border-4 md:border-6 lg:border-8 border-[#FF4949] rounded-full flex items-center justify-center">
            <span className="text-white text-center text-lg font-bold">
              USDT <br /> {difference}
            </span>
          </div>
          <div className="block w-1/4">
            <div className="mt-2 text-[10px] md:text-sm lg:text-base text-gray-300 flex ">
              <p className="text-lg lg:text-base">{percentageDifference.toFixed(2)}%</p>
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={3}
                stroke="currentColor"
                className="size-5 ml-7 self-center"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m4.5 15.75 7.5-7.5 7.5 7.5"
                />
              </svg> */}
            </div>
            <p className="mt-2 text-sm md:text-lg font-bold">
              Total Amount <br /> Earned
            </p>
          </div>
        </div>
        <div className="bg-[#450892] text-white rounded-lg shadow-md flex justify-between pl-5 pr-16 py-10">
          <div className="w-32 h-32 md:w-32 md:h-32 lg:w-28 lg:h-28 xl:w-32 xl:h-32 2xl:w-40 2xl:h-40 flex-shrink-0 bg-none border-4 md:border-6 lg:border-8 border-[#FF4949] rounded-full flex items-center justify-center">
            <span className="text-white text-center text-lg font-bold">
              {customerData?.totalTransactions || 0}
            </span>
          </div>
          <div className="block w-1/4">
            <p className="text-lg lg:text-base">
              {customerData?.totalTransactions || 0}
            </p>
            <p className="mt-2 text-sm md:text-lg font-bold">
              Total <br /> Transaction
            </p>
          </div>
        </div>
      </div>
      <div>
        <BarChart />
      </div>
    </div>
  );
};

export default CustomerStatistic;
