import React from 'react';
import Status from '../../../../static/enums/statusEnum';

const CustomersRows = ({ item, index }) => {
  return (
    <div
      className={`flex items-center md:px-6 md:py-4 px-1 py-2 border md:my-4 my-2 border-grey-light rounded-full space-x-5`}
    >
      <span className="tr_style w-1/7 text-gray-600 font-semibold">{index + 1}</span>
      <span className="tr_style w-1/7">
        {item.account ? (
          <>
            {item.account.firstName} <br /> {item.account.lastName}
          </>
        ) : (
          '-'
        )}
      </span>
      <span className="tr_style w-1/7">{item.id}</span>
      <span className="tr_style w-1/7">
        {item.account.country?.name || '-'}
      </span>
      <span className="tr_style w-1/7">
        <span className="flex items-center w-full">
          <span
            className={`md:py-1.5 py-0.5 md:text-xs text-[5px] self-center font-semibold rounded-full flex-grow text-center ${
              Number(item.account.status) === 3
                ? 'bg-[#5DF888] text-black'
                : Number(item.account.status) === 2
                ? 'bg-[#FF4949] text-white'
                : 'bg-[#716dc0]'
            }`}
          >
            {Status.find(
              (status) => status.value === Number(item.account.status)
            )?.label || 'Suspended'}
          </span>
        </span>
      </span>
      <span className="tr_style w-1/7">
        {item.account.datetimeInserted
          ? new Date(item.account.datetimeInserted).toLocaleDateString('en-GB')
          : '-'}
      </span>
      <span className={`tr_style w-1/7 ${item?.currentWalletAmount > 0 ? "text-black" : "text-red-500"}`}>
        {item?.currentWalletAmount}
      </span>
    </div>
  );
};

export default CustomersRows;
