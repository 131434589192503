import React from 'react';
import Logo from '../static/img/Logo.png';
import CompanyName from '../static/img/MarsCapita.png';
import MobileCompanyName from '../static/img/MarsCapitaHeader.png';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const CompanyLogo = () => {
    const location = useLocation();
    let chooseClass = location.pathname.includes('/connect');

    return (
        <div
            className={`sm:grid bg-mc_white ${
                chooseClass ? 'sm:grid-cols-5' : 'sm:grid-cols-3'
            }`}
        >
            <div className='bg-mc_purple sm:min-h-screen w-full sm:w-auto flex flex-col justify-center items-center sm:col-span-1 '>
                <div>
                    <div className='flex flex-col items-center'>
                        <img
                            src={Logo}
                            alt='Logo'
                            className='w-1/4 sm:w-4/5 -mb-9 sm:m-0'
                        />
                        <img
                            src={CompanyName}
                            alt='MarsCapita'
                            className='hidden sm:block z-10 w-3/5 -mt-7'
                        />
                    </div>
                    <div
                        className={`px-5 font-poppins text-center font-medium text-white ${
                            chooseClass
                                ? 'hidden sm:block text-sm md:text-lg'
                                : 'hidden sm:block md:text-base lg:text-xl'
                        }`}
                    >
                        Your Fund | Your Account <br /> Your Profit{' '}
                    </div>
                </div>
            </div>
            <div className='sm:hidden w-full flex justify-center mt-5 '>
                <img src={MobileCompanyName} alt='MarsCapita'  className='h-10' />
            </div>
            <div
                className={`${
                    chooseClass ? 'sm:col-span-4' : 'sm:col-span-2'
                } min-h-screen flex justify-center items-start mt-0 sm:items-center`}
            >
                <Outlet />
            </div>
        </div>
    );
};

export default CompanyLogo;
