import React, { useEffect, useRef } from 'react';

const ExchangeModal = ({ isOpen, onClose, selectedExchange, connectExchange }) => {
	const modalRef = useRef();

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        document.addEventListener('touchstart', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            document.removeEventListener('touchstart', handleOutsideClick);
        };
    }, [onClose]);
	
	if (!isOpen) return null;

	return (
		<div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 font-poppins">
			<div ref={modalRef} className="bg-white xl:p-6 rounded-lg shadow-lg sm:w-4/6 w-5/6">
				<div className="w-full text-end p-3">
					<button onClick={onClose}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth="1.5"
							stroke="#444444"
							className="size-4"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M6 18 18 6M6 6l12 12"
							/>
						</svg>
					</button>
				</div>
				<div className="w-full flex flex-col items-center justify-center mt-8 xl:px-32 lg:px-28 md:px-24 sm:px-20 px-10">
					<img className="w-24 md:w-28" src={selectedExchange} alt="" />
					<div className="font-medium text-base md:text-xl text-center mt-6 lg:mt-16 mb-5">
						Authorise third-party data access so they can provide you with
						services.
					</div>
					<div className="w-full p-5 bg-[#D9D9D9] rounded-lg text-xs font-medium">
						Quick API <br />
						Authorise third-party to create API Keys with read only and trading
						permission{' '}
					</div>
					<button
						className="flex items-center w-full h-11 p-3 my-10 font-medium rounded-xl text-sm text-black shadow-md outline-none bg-opacity-50 bg-mc_blue"
						onClick={connectExchange}
					>
						<span className="grow">Next</span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							fill="#5C5C5C"
							className="size-4"
						>
							<path
								fillRule="evenodd"
								d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z"
								clipRule="evenodd"
							/>
						</svg>
					</button>
				</div>
			</div>
		</div>
	);
};

export default ExchangeModal;
