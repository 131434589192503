import React from 'react';
import {EntryType} from '../../../static/enums/entryTypeEnum';
import {EntryDirection} from '../../../static/enums/strategiesEnums';
import {useNavigate} from 'react-router-dom';

const EntryOrderRow = ({ item, index, isSelected }) => {
  const navigate = useNavigate();
  const orderTypeLabel =
    EntryDirection.find((type) => type.value === item.strategy.entryDirection)
      ?.label || '-';

  const entryTypeLabel =
    Object.keys(EntryType).find((key) => EntryType[key] === item.entryType) ||
    '-';

  return (
    <div
      className={`flex items-center md:px-6 md:py-4 px-1 py-2 border md:my-4 my-2 border-grey-light rounded-full space-x-5 ${
        isSelected ? 'bg-gray-100' : ''
      }`}
    >
      <span className="tr_style w-1/8 text-gray-600 font-semibold">{index + 1}</span>
      <span
         className={`tr_style w-1/8 ${item.customer.firstName !== "Admin" ? "cursor-pointer" : "cursor-default"}`}
        onClick={() => {
          if (item.customer.firstName !== 'Admin') {
            navigate(`/customer/${item.customer.id}`);
          }
        }}
      >
        {item.customer.firstName} <br /> {item.customer.lastName}
      </span>
      {item.customer.agent ? (
        <a href={`/agent/${item.customer.agent.id}`} className="tr_style w-1/8">
          <span>
            {item.customer.agent.firstName ? (
              <>
                {item.customer.agent.firstName} <br /> {item.customer.agent.lastName}
              </>
            ) : (
              '-'
            )}
          </span>
        </a>
      ) : (
        <span className="tr_style w-1/8">No agent</span>
      )}
      <span className="tr_style w-1/8"
            onClick={() => navigate(`/strategy/${item.strategy.id}`)}>{item.transactionId}</span>
      <span className="tr_style w-1/8">{orderTypeLabel || '-'}</span>
      <span className="tr_style w-1/8">{entryTypeLabel}</span>
      <span className="tr_style w-1/8">
        {item.datetimeInserted
          ? (() => {
              const dateTimeString = new Date(
                item.datetimeInserted
              ).toLocaleString('en-GB', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
              });
              const [date, time] = dateTimeString.split(', ');
              return (
                <>
                  {date} <br /> {time}{' '}
                </>
              );
            })()
          : '-'}
      </span>
      <span className="tr_style w-1/8">{item.message || '-'}</span>
    </div>
  );
};

export default EntryOrderRow;
