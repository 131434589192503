/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import CustomRadio from '../../PreParameters/CustomRadio';
import ResetPasswordModal from '../../../shared/ResetPasswordModal';
import DropDownCountry from '../../../shared/DropDownCountry';
import { getAllCountries } from '../../../../api/countryApi';
import Gender from '../../../../static/enums/genderEnum';
import RadioValue from '../../../shared/RadioValue';
import DropdownStatus from '../../../shared/DropDownStatus';
import ModalConfirmation from '../../../shared/ModalConfirmation';
import Modal from '../../../shared/Modal';
import {
	deleteCustomerById,
	updateCustomerById,
	updateGroup,
} from '../../../../api/customerApi';
import { PlatformType } from '../../../../static/enums/platformTypeEnum';

import Cookies from 'js-cookie';
import { AccountType } from '../../../../static/enums/accountTypeEmum';
import Status from '../../../../static/enums/statusEnum';
import { useNavigate } from 'react-router-dom';
import { changePassword } from '../../../../api/accountApi';
import { updateStatus } from '../../../../api/securityApi';
import { CustomerGroup } from '../../../../static/enums/customerGroupEnum';
import DropDownSimple from '../../../shared/DropDownSimple';

export const referralCode = [
	{ value: 'Yes', label: 'Yes' },
	{ value: 'No', label: 'No' },
];

const CustomerInfo = ({ customerData,  accountType }) => {
  const navigate = useNavigate();
  const [selectedGender, setSelectedGender] = useState(
    customerData?.account?.gender || 1
  );
  const [firstName, setFirstName] = useState(
    customerData?.account?.firstName || ''
  );
  const [lastName, setLastName] = useState(
    customerData?.account?.lastName || ''
  );
  const [selectedReferralCode, setSelectedReferralCode] = useState(
    customerData?.referralCode || 'No'
  );
  const [email, setEmail] = useState(customerData?.account?.email || '');
  const [age, setAge] = useState(customerData?.account?.age || '');
  const [mobile, setMobile] = useState(customerData?.account?.phone || '');
  const [countryDropdown, setCountryDropdown] = useState(false);
  const [country, setCountry] = useState({
    selectedFlag: customerData?.account?.country?.imageUrl || '',
    selectedName: customerData?.account?.country?.name || '',
    selectedId: customerData?.account?.country?.id || '',
  });
  const [agent, setAgent] = useState(() => {
	const firstName = customerData?.agent?.firstName || '';
	const lastName = customerData?.agent?.lastName || '';

		return firstName || lastName ? `${firstName} ${lastName}`.trim() : '';
	});

	const [exchange, setExchange] = useState(customerData?.platformType || '');
	const [exchangeAccountUID, setExchangeAccountUID] = useState(
		customerData?.exchangeAccountUUID || ''
	);
	const [walletValue, setWalletValue] = useState(customerData?.currentWalletAmount);
	const [apiKey, setApiKey] = useState(customerData?.apiKey || '');
	const [apiSecretKey, setApiSecretKey] = useState(
		customerData?.apiSecret || ''
	);
	const [inputValue, setInputValue] = useState(`${country.selectedName}`);
	const [countries, setCountries] = useState([]);
	const [searchCountry, setSearchCountry] = useState('');
	const [status, setStatus] = useState(customerData?.account?.status);
	const [showModal, setShowModal] = useState(false);
	const [showSaveModal, setShowSaveModal] = useState(false);
	const [pendingStatus, setPendingStatus] = useState('');
	const [isFormChanged, setIsFormChanged] = useState(false);
	const [modalMessage, setModalMessage] = useState('');
	const [modalType, setModalType] = useState('success');
	const [isAccountDeleted, setIsAccountDeleted] = useState(false);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [errorPassword, setErrorPassword] = useState('');
	const [successPassword, setSuccessPassword] = useState('');
	const [disable, setDisable] = useState(false);

	const [selectedGroup, setSelectedGroup] = useState(
		customerData?.group || null
	);

	console.log(customerData)

	const customerGroupOptions = Object.entries(CustomerGroup).map(
		([key, value]) => ({
			value: value,
			label: key,
		})
	);

	useEffect(() => {
		const patchGroup = async () => {
			const token = Cookies.get('accessToken');
			if (!token) {
				return { account: null, statusCode: 401 };
			}
			if (selectedGroup !== null) {
				try {
					const response = await updateGroup(
						token,
						customerData.id,
						selectedGroup
					);
					console.log('Group updated successfully', response);
				} catch (error) {
					console.error('Error updating group:', error);
				}
			}
		};

		patchGroup();
	}, [selectedGroup]);

	useEffect(() => {
		const isChanged =
			selectedGender !== customerData?.account?.gender ||
			firstName !== customerData?.account?.firstName ||
			lastName !== customerData?.account?.lastName ||
			email !== customerData?.account?.email ||
			age !== customerData?.account?.age ||
			mobile !== customerData?.account?.phone ||
			(country.selectedId || '') !==
				(customerData?.account?.country?.id || '') ||
			status !== customerData?.account?.status;

		setIsFormChanged(isChanged);
	}, [
		selectedGender,
		firstName,
		lastName,
		email,
		age,
		mobile,
		country,
		status,
		customerData,
	]);

	useEffect(() => {
		const fetchCountries = async () => {
			try {
				const data = await getAllCountries(searchCountry);
				setCountries(data);
			} catch (error) {
				console.error('Failed to fetch countries:', error);
			}
		};
		fetchCountries();
	}, [searchCountry]);

	useEffect(() => {
		if (!showSaveModal && isAccountDeleted) {
			navigate('/customers');
		}
	}, [showSaveModal, isAccountDeleted, navigate]);

	const openCountryDropdownHandler = (e) => {
		e.preventDefault();
		setCountryDropdown(!countryDropdown);
	};

	const closeCountryDropdownHandler = () => {
		setCountryDropdown(false);
	};

	const handleCountrySelect = (flagUrl, name, id) => {
		setCountry({
			selectedFlag: flagUrl,
			selectedName: name,
			selectedId: id,
		});
		setInputValue(name);
		closeCountryDropdownHandler();
	};

	const getStatusLabelByValue = (value) => {
		const status = Status.find((status) => status.value === value);
		return status ? status.label : '';
	};

	const getStatusValueByLabel = (label) => {
		const status = Status.find((status) => status.label === label);
		return status ? status.value : '';
	};

	const handleStatusChange = (newStatus) => {
		setPendingStatus(newStatus);
		setShowModal(true);
	};

	const confirmStatusChange = async () => {
		setStatus(pendingStatus);
		if (pendingStatus === 0) {
			try {
				const token = Cookies.get('accessToken');
				if (!token) {
					return { account: null, statusCode: 401 };
				}
				const result = await deleteCustomerById(customerData.id, token);
				if (result.successful) {
					setModalMessage('Account delete successfully!');
					setShowSaveModal(true);
					setIsFormChanged(false);
					setIsAccountDeleted(true);
				} else {
					setModalMessage(result.message || 'Failed to delete the account');
					setModalType('error');
					setShowSaveModal(true);
				}
			} catch (error) {
				setModalMessage('An unexpected error occurred');
				setModalType('error');
				setShowSaveModal(true);
			}
		} else {
			try {
				const token = Cookies.get('accessToken');
				const newStatus = await updateStatus(
					token,
					customerData.account.id,
					pendingStatus
				);

				if (getStatusLabelByValue(pendingStatus) === 'Active') {
					setModalMessage(['Customer acccount successfully unblocked.']);
				} else {
					setModalMessage(['Customer acccount successfully blocked.']);
				}
				setModalType('success');
				setIsFormChanged(false);
			} catch (error) {
				setModalMessage('Failed to update customer data. Please try again.');
				setModalType('error');
			}

			setShowSaveModal(true);
		}
		setShowModal(false);
	};

	const handlePasswordChange = async (password) => {
		const token = Cookies.get('accessToken');

    if (!token) {
      console.error('Authorization token not found');
      return;
    }
    try {
      const response = await changePassword(
        customerData.account.id,
        password,
        token
      );
      if (response.successful) {
        setSuccessPassword('Password reset successful!');
        setDisable(true);
      } else {
        setErrorPassword('Password change failed');
      }
    } catch (error) {
      setErrorPassword('Password change failed');
    }
  };

	return (
		<div className="p-1">
			<form className="container flex-col items-start ">
				<div className="form-container bg-white  md:px-12 py-2 px-2 rounnded-md">
					<div className="grid grid-cols-2 md:gap-10 mb-6">
						<div>
							<label className="block md:text-sm text-[6px] font-medium text-gray-700">
								First Name
							</label>
							<input
								type="text"
								className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
								value={firstName}
								onChange={(e) => setFirstName(e.target.value)}
								disabled
								required
							/>
						</div>
						{/* Last Name */}
						<div>
							<label className="block md:text-sm text-[6px] font-medium text-gray-700">
								Last Name
							</label>
							<input
								type="text"
								className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
								value={lastName}
								onChange={(e) => setLastName(e.target.value)}
								disabled
								required
							/>
						</div>
					</div>
					<div className="grid grid-cols-2 md:gap-10 mb-6">
						{/* Age */}
						<div>
							<label className="block md:text-sm text-[6px] font-medium text-gray-700">
								Age
							</label>
							<input
								type="number"
								className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
								value={age}
								onChange={(e) => {
									const newValue = parseInt(e.target.value, 10);
									setAge(newValue >= 0 ? newValue : 0);
								}}
								min={0}
								disabled
								required
							/>
						</div>
						{/* Gender */}
						<div>
							<label className="block md:text-sm text-[6px] font-medium text-gray-700 mb-4">
								Gender
							</label>
							<div className="mt-1 flex-row items-center">
								<RadioValue
									name="gender"
									items={Gender}
									value={selectedGender}
									onChange={setSelectedGender}
									disabled
								/>
							</div>
						</div>
					</div>
					<div className="grid grid-cols-2 md:gap-10 mb-6 ">
						{/* Email */}
						<div>
							<label className="block md:text-sm text-[6px] font-medium text-gray-700">
								Email
							</label>
							<input
								type="email"
								className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								disabled
								required
							/>
						</div>
						{/* Mobile */}
						<div>
							<label className="block md:text-sm text-[6px] font-medium text-gray-700">
								Mobile
							</label>
							<input
								type="text"
								className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
								value={mobile}
								onChange={(e) => setMobile(e.target.value)}
								disabled
								required
							/>
						</div>
					</div>
					<div className="grid grid-cols-2 md:gap-10 mb-6">
						{/* Country */}
						<div>
							<label className="block md:text-sm text-[6px] font-medium text-gray-700">
								Country
							</label>
							<div>
								<button
									onClick={openCountryDropdownHandler}
									className="mt-1 block w-full p-2 border border-gray-300 rounded-md text-left disabled:bg-mc_white"
									disabled
								>
									{country.selectedId ? (
										<span>{country.selectedName}</span>
									) : (
										<span className="text-xs">No selected country</span>
									)}
								</button>
								{countryDropdown && (
									<DropDownCountry
										countries={countries}
										onCountrySelect={handleCountrySelect}
										onClose={closeCountryDropdownHandler}
										value={inputValue}
										searchValue={searchCountry}
										setSearchValue={setSearchCountry}
										onChange={(e) => setInputValue(e.target.value)}
										disabled={accountType === AccountType.Employee}
									/>
								)}
							</div>
						</div>
						{/* Agent */}
						<div>
							<label className="block md:text-sm text-[6px] font-medium text-gray-700">
								Agent
							</label>
							<input
								type="text"
								className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
								value={agent}
								onChange={(e) => setAgent(e.target.value)}
								disabled
							/>
						</div>
					</div>
					<div className="grid grid-cols-2 md:gap-10 mb-6">
						<div className="relative">
							<label className="block text-sm font-medium text-gray-700 mb-1">
								Password
							</label>
							<div className="relative">
								<input
									type="password"
									className="block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
									placeholder="Enter Password here"
									value="********"
									disabled={accountType === AccountType.Employee}
									readOnly
								/>
								<button
									type="button"
									className="absolute inset-y-0 right-0 flex items-center px-3"
									onClick={() => setIsModalOpen(true)} // Open modal on click
									disabled={accountType === AccountType.Employee}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth={1.5}
										stroke="currentColor"
										className="w-6 h-6 text-gray-500"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
										/>
									</svg>
								</button>
							</div>
						</div>

						<div>
							<label className="block md:text-sm text-[6px] font-medium text-gray-700 mb-1">
								Group
							</label>
							<DropDownSimple
								options={customerGroupOptions}
								selectedValue={selectedGroup}
								onChange={setSelectedGroup}
								name={'Select Group'}
								required
							/>
						</div>

						{/* Referral Code
						<div>
							<label className="block md:text-sm text-[6px] font-medium text-gray-700 mb-4">
								MS. Referral Code (Used)
							</label>
							<div className="mt-1 flex-row items-center ">
								<CustomRadio
									name="referalCode"
									items={referralCode}
									value={selectedReferralCode}
									onChange={setSelectedReferralCode}
									disabled
								/>
							</div>
						</div> */}
					</div>
					{/* <span className="mt-3 flex text-btn-red text-center justify-end font-semibold mb-1 md:text-[10px] text-[5px]">
						MarsCAPITA Referral Code from Exchanges
					</span> */}
					<hr className="text-black mb-8" />
					<div className="grid grid-cols-2 md:gap-10 mb-6">
						{/* Exchange */}
						<div>
							<label className="block md:text-sm text-[6px] font-medium text-gray-700">
								Exchange
							</label>
							<input
								type="text"
								className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
								value={
									PlatformType.find((p) => p.id === customerData?.platformType)
										?.name || ''
								}
								onChange={(e) => setExchangeAccountUID(e.target.value)}
								disabled
							/>
							{/* <ExchangeDrop
                exchanges={PlatformType} 
                selectedExchange={exchange} 
                onSelect={setExchange} // 
              /> */}
						</div>
						{/* Exchange Account UID */}
						<div>
							<label className="block md:text-sm text-[6px] font-medium text-gray-700">
								Exchange Account UID
							</label>
							<input
								type="text"
								className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
								value={exchangeAccountUID}
								onChange={(e) => setExchangeAccountUID(e.target.value)}
								disabled
							/>
						</div>
					</div>
					<div className="grid grid-cols-2 md:gap-10 mb-6">
						{/* API Key */}
						<div>
							<label className="block md:text-sm text-[6px] font-medium text-gray-700">
								API Key
							</label>
							<input
								type="text"
								className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
								value={apiKey}
								onChange={(e) => setApiKey(e.target.value)}
								disabled
							/>
						</div>
						{/* API Secret Key */}
						<div>
							<label className="block md:text-sm text-[6px] font-medium text-gray-700">
								API Secret Key
							</label>
							<input
								type="text"
								className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
								value={apiSecretKey}
								onChange={(e) => setApiSecretKey(e.target.value)}
								disabled
							/>
						</div>
					</div>
					<div className="grid grid-cols-2 md:gap-10 mb-6">
						
						<div className="col-start-1">
							<label className="block md:text-sm text-[6px] font-medium text-gray-700">
								Wallet Value
							</label>
							<input
								type="text"
								className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:bg-mc_white"
								value={walletValue}
								onChange={(e) => setWalletValue(e.target.value)}
								disabled
							/>
						</div>
					</div>
				</div>
				<div className="grid col-span-3 mt-10 bg-grey-light">
					{/* <div className="flex justify-between space-x-6 col-start-2"> */}
					{/* <div className="flex-1">
							<button
								type="submit"
								className="w-full text-white p-3.5 rounded-full md:text-base text-[8px] bg-green-500 hover:bg-green-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
								disabled={
									!isFormChanged || accountType === AccountType.Employee
								}
							>
								Save information
							</button>
						</div> */}
					<div className="col-start-3">
						<DropdownStatus
							options={[
								{
									label: 'Block',
									value: getStatusValueByLabel('Blocked'),
									disabled: status === getStatusValueByLabel('Blocked'),
								},
								{
									label: 'Unblock',
									value: getStatusValueByLabel('Active'),
									disabled: status === getStatusValueByLabel('Active'),
								},
								{ label: 'Delete Account', value: 0 },
							]}
							selected={{
								value: status,
								label:
									status === 0
										? 'Delete Account'
										: getStatusLabelByValue(status),
							}}
							onSelect={handleStatusChange}
							disabled={accountType === AccountType.Employee}
							className={`w-full p-3 rounded-full md:text-base text-[8px] ${
								getStatusLabelByValue(status) === 'Active'
									? 'bg-green-500 text-white'
									: 'bg-red-500 text-white'
							}`}
						/>
					</div>
					{/* </div> */}
				</div>
			</form>
			<ModalConfirmation
				isVisible={showModal}
				onCancel={() => setShowModal(false)}
				onConfirm={confirmStatusChange}
				pendingStatus={
					pendingStatus !== 0
						? `${
								getStatusLabelByValue(pendingStatus) === 'Blocked'
									? 'Confirm block user?'
									: 'Confirm unblock user?'
						  }`
						: 'Confirm delete this account'
				}
				modalType="delete"
			/>
			<Modal
				isOpen={showSaveModal}
				onClose={() => setShowSaveModal(false)}
				message={modalMessage}
				type={modalType}
			/>
			{isModalOpen && (
				<ResetPasswordModal
					onClose={() => {
						setIsModalOpen(false);
						setDisable(false);
					}}
					onPasswordChange={handlePasswordChange}
					error={errorPassword}
					setError={(e) => setErrorPassword(e)}
					success={successPassword}
					setSuccess={(s) => setSuccessPassword(s)}
					disable={disable}
				/>
			)}
		</div>
	);
};

export default CustomerInfo;
