import React, { useEffect, useRef, useState } from 'react';
import { loginUser } from '../api/securityApi';
import TwoFactVereficantionModal from '../components/modals/TwoFactVereficantionModal';
import ResetPasswordModal from '../components/modals/ResetPasswordModal';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import usePageTitle from '../hooks/usePageTitle';
import BlockedAccountModal from '../components/modals/BlockedAccountModal';
import { AccountType } from '../static/enums/accountTypeEmum';

const LoginPage = () => {
	usePageTitle('Log in to your account');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [isResetPasswordModal, setIsResetPasswordModal] = useState(false);
	const [error, setError] = useState('');
	const [requires2FA, setRequires2FA] = useState(false);
	const [isBlockedModal, setIsBlockedModal] = useState(false);
	const [errors, setErrors] = useState({ email: false, password: false });
	const [userData, setUserData] = useState({
		accessToken: '',
		email: '',
		accountType: '',
	});
	const navigate = useNavigate();

	const buttonRef = useRef(null);
	const [width, setWidth] = useState(0);


	const resetPasswordModalHandler = () => {
		setIsResetPasswordModal(!isResetPasswordModal);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const newErrors = {
			email: !email,
			password: !password,
		};
		setErrors(newErrors);

		if (Object.values(newErrors).some((error) => error)) {
			setError('Please fill in all required fields.');
			return;
		}

		setError('');
		try {
			const result = await loginUser(email, password);

			if (result.successful) {
				const { requires2FA, accessToken, accountId, email, accountType } =
					result.data;

				setUserData({
					accountId,
					email,
					accountType,
				});

				if (requires2FA) {
					setRequires2FA(true);
				} else if (accessToken) {
					Cookies.set('accessToken', accessToken.value, {
						expires: 30,
						path: '/',
					});
					if (accountType === AccountType.Agent) {
						navigate('/agent/dashboard');
					} else if (accountType === AccountType.Admin) {
						navigate('/admin/dashboard');
					} else if (accountType === AccountType.Employee) {
						navigate('/employee/dashboard');
					}  else if (accountType === AccountType.Customer) {
						navigate('/customer/dashboard'); }
				}
			} else {
				const status = result.error?.status;
				if (status === 403) {
					setIsBlockedModal(true)
				} else {
					setError(result.error?.message || 'Login failed');
				}
			}
		} catch (error) {
			const errorMessage = error.response
				? error.response.data.error.message
				: error.message;

			setError(errorMessage || 'An unexpected error occurred.');
		}

		setErrors({ email: false, password: false });
	};

	const updateWidth = () => {
		if (buttonRef.current) {
			const buttonWidth = buttonRef.current.getBoundingClientRect().width;
			setWidth(buttonWidth);
		}
	};

	useEffect(() => {
		updateWidth();

		window.addEventListener('resize', updateWidth);

		return () => {
			window.removeEventListener('resize', updateWidth);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="font-poppins w-full px-7 sm:w-auto sm:p-0 sm:min-w-80 mt-12">
			<div className="text-mc_purple text-xl sm:text-4xl mb-2">LOGIN</div>
			<form onSubmit={handleSubmit} className="flex flex-col justify-between">
				<div>
					<input
						id="LoginEmail"
						name="email"
						placeholder="Email"
						type="email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						className={`block w-full h-11 p-3 mb-5 font-normal rounded-[5px] border border-mc_light_grey ${
							errors.email || error
								? 'bg-red-500 bg-opacity-30'
								: 'bg-transparent'
						} outline-none text-sm sm:text-base placeholder:text-black placeholder:text-sm placeholder:sm:text-base`}
					/>
					<input
						id="password"
						name="password"
						placeholder="Password"
						type="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						className={`block w-full h-11 p-3 mb-24 font-normal rounded-[5px] border border-mc_light_grey ${
							errors.password || error
								? 'bg-red-500 bg-opacity-30'
								: 'bg-transparent'
						} outline-none text-sm sm:text-base placeholder:text-black placeholder:text-sm placeholder:sm:text-base`}
					/>
					<div>
						<div className="text-red-500 text-sm sm:text-base text-center mb-2 h-6">{error}</div>
						<button ref={buttonRef} className="flex items-center w-full h-11 p-3 font-normal rounded-xl text-base sm:text-lg text-black shadow-md outline-none bg-opacity-50 bg-mc_blue">
						<span className="grow">LOGIN</span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							fill="#5C5C5C"
							className="size-4"
						>
							<path
								fillRule="evenodd"
								d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z"
								clipRule="evenodd"
							/>
						</svg>
					</button>
					</div>
				</div>
			</form>

			<TwoFactVereficantionModal
				userData={userData}
				isOpen={requires2FA}
				onClose={() => setRequires2FA(false)}
			/>
			<button
				onClick={resetPasswordModalHandler}
				className="text-red-500 text-xs sm:text-sm w-full text-center mt-2"
			>
				Forgot Password
			</button>
			<ResetPasswordModal
				isOpen={isResetPasswordModal}
				onClose={resetPasswordModalHandler}
				width={width}
			/>
			<div className="text-xs sm:text-sm w-full mt-4 font-normal text-center">
				Don't have an account?
				<span className="ml-1 text-mc_blue underline">
					<Link to="/auth/register">Create here</Link>
				</span>
			</div>
			<BlockedAccountModal
				isOpen={isBlockedModal}
				onClose={() => setIsBlockedModal(!isBlockedModal)}
			/>
		</div>
	);
};

export default LoginPage;
