import React from 'react';

const EntryOrderHeader = ({ selectAll, handleSelectAll }) => {
  return (
    <div className="flex items-center md:px-6 px-1 py-2 font-bold text-sm bg-white space-x-5">
      <span className="tr_style w-1/8">№</span>
      <span className="tr_style w-1/8">Customer ID </span>
      <span className="tr_style w-1/8">Agent</span>
      <span className="tr_style w-1/8">Transaction ID </span>
      <span className="tr_style w-1/8">Order Type</span>
      <span className="tr_style w-1/8">Entry Type</span>
      <span className="tr_style w-1/8">(Date/Time)</span>
      <span className="tr_style w-1/8">Status</span>
    </div>
  );
};

export default EntryOrderHeader;
